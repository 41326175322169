@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400&display=swap');

html {
    font-size: 10px;
}
body {
    font-size: 1.8rem;
    font-family: Roboto,sans-serif;
    font-weight: 300;
    line-height: 1.5;
    color: #404345;
}
h1, h2, h3, .h1, .h2, .h3 {
    font-weight: 100;
}
h1, .h1 {
    font-size: 5.4rem;
}
h2, .h2 {
    font-size: 5.4rem;
}
h3, .h3 {
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 3rem;
    line-height: 1;

    a {
    color: #404345;

        &:hover,
        &:focus {
            color: #404345;
            text-decoration: none;
        }
    }
}

table td {
    text-align: left;
}
a:focus,
button:focus {
    outline: 0;
}
img {
    max-width: 100%;
}
.slick-slide img {
    border-radius: 10px;
}
.preview-screen {
    padding: 5rem 0;
    background: #fafafa;

    .computer {
        margin-left: -30%;
        z-index: 0;
        max-width: 150%;
    }
    .iphone-image {
        padding-top: 40px;
        margin-top: 4%;
        position: relative;
        z-index: 1;
    }
}

@media (max-width: 992px) {
    .preview-screen .computer {
        margin-left: -35%;
        max-width: 130%;
    }
}

.container-box {
    max-width: 960px;
    margin: 0 auto;
}
.share-screen .image-container img,
.untraceable-screen .frame-container img,
.untraceable-screen .logo-container img {
    max-width: 60px;
}
.title-screen {
    background: url(../img/first_screen.jpg) center no-repeat;
    background-size: cover;
}
.technology-screen {
    padding: 7rem 0 9rem;
}
.companies-screen {
    -webkit-box-shadow: inset 0 8px 15px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 8px 15px 0 rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 8px 15px 0 rgba(0, 0, 0, 0.3);
    padding: 7rem 0;
    background: url(../img/companies-bg.jpg) center no-repeat;
    background-size: cover;

    h2,
    p {
        color: #fff;
    }
    .company-logo {
        margin: 15px auto;
    }
}

@media (max-width: 767px) {
    .companies-screen .company-logo {
        padding-top: 25px;
    }
}
.privacy-policy {
    padding: 5rem 0;
}

.untraceable-screen {
    padding: 5rem 0 0;

    .frame-container,
    .logo-container {
        padding: 20px;
        background: #ececec;
        border-radius: 15px;
        margin-bottom: 50px;
    }
    .logo-container {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            display: block;
            height: 35px;
            width: 15px;
            right: 50%;
            bottom: -45px;
            margin-top: -22px;
            background: url(../img/arrow-right.png) no-repeat;
            background-size: contain;
            transform: rotate(90deg);
        }
        &.center:after {
            right: 50%;
        }
        &.left:after {
            right: 83%;
        }
        &.right:after {
            right: 17%;
        }
        .logo-button {
            opacity: 0.4;
            padding: 20px 0;
        }
        .logo-button div {
            min-height: 90px;
        }
        .logo-button span {
            color: #445e5c;
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 400;
            padding: 3px;
        }
        .logo-button.active,
        .logo-button:hover {
            opacity: 1;
        }
        .logo-button.active a,
        .logo-button:hover a {
            text-decoration: none;
        }
        .logo-button.active a span,
        .logo-button:hover a span {
            color: #aa9059;
            border-bottom: 2px solid #aa9059;
        }
    }
    
    .slide-container {
        position: relative;
    }
    .slide-container .slide {
        margin: 20px 0;
    }
    .image-container {
        display: none;
    }
    .image-container.active {
        display: block;
    }
    button {
        border: none;
        background: #d3d7d7;
        color: #445e5c;
        text-transform: uppercase;
        border-radius: 15px;
        padding: 15px 30px;
        font-size: 1.4rem;
        line-height: 1;
        cursor: pointer;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
    }

    button.active,
    button:hover {
        background: #445e5c;
        color: #fff;
    }
    #copy-button {
        background: 0 0;
        border: 1px solid #445e5c;
        position: relative;
        transition: all 0.5s;

        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
    }
    #copy-button.zeroclipboard-is-hover {
        background: #445e5c;
        color: #fff;
    }
    #copy-button.zeroclipboard-is-active:before {
        content: '';
        opacity: 1;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0;
        border-color: #445e5c transparent transparent;
        top: -10px;
        left: 37px;
    }
    #copy-button.zeroclipboard-is-active:after {
        opacity: 1;
        content: 'Saved to clipboard';
        background: #445e5c;
        color: #fff;
        font-weight: 100;
        font-size: 1rem;
        line-height: 1;
        padding: 10px 15px;
        border-radius: 10px;
        position: absolute;
        top: -40px;
        width: 160%;
        left: -30%;
        text-transform: capitalize;
    }
}

.share-screen {
    background: url(../img/noise.jpg);
    padding: 10rem 0 8rem;
    color: #fff;
    font-size: 1.6rem;
    line-height: 1.5;

    .sub-title {
        font-weight: 300;
        font-size: 3rem;
        line-height: 1;
        margin-bottom: 25px;
    }
    .image-container {
        min-height: 80px;
    }
    a.share {
        height: 42px;
    }
    a.share img {
        margin-top: -42px;
    }
    a.share:hover img {
        margin: 0;
    }
    a {
        color: #fff;
        text-decoration: none;
        overflow: hidden;
    }
    a.underline {
        text-decoration: underline;
    }
    a.underline:hover {
        opacity: 1;
        text-decoration: none;
    }
    .button-container {
        margin-top: 25px;
        margin-bottom: 40px;
    }
    .donate-button {
        border: 1px solid #fff;
        color: #fff;
        border-radius: 10px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 1;
        padding: 13px 30px;
        display: inline-block;
        transition: all 0.5s;
    }
    .donate-button:hover {
        background: #a1a4a4;
        border-color: #a1a4a4;
        color: #404345;
    }
}
.footer-screen {
    padding: 75px 0 25px;
    background: #1e2323;
    color: #fff;

    .menu-container {
        margin-bottom: 35px;

        .footer-logo {
            max-height: 50px;
        }
        .footer-navigation ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
        .footer-navigation ul li {
            display: inline;
            padding: 0 20px;
            border-right: 1px solid #fff;
        }
        .footer-navigation ul li:last-of-type {
            border: none;
        }
        .footer-navigation ul li a {
            color: #fff;
            font-size: 1.4rem;
            line-height: 3.57;
        }
    }
}

.navbar {
    padding-top: 30px;

    li a {
        color: #fff;
        text-transform: uppercase;
        font-size: 1.4rem;
        line-height: 5;
    }
    li.active a,
    li a:focus,
    li a:hover {
        background: none;
        color: #aa9059;
    }
}

.navbar-brand img {
    padding-top: 10px;
}

.title-header {
    font-weight: 100;
    font-size: 5.4rem;
    line-height: 1;

    &.light-header {
        color: #b3965a;
        text-transform: capitalize;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
        margin-bottom: 20px;
    }
    &.dark-header {
        color: #3c5250;
        margin-bottom: 20px;
    }
    &.white-header {
        margin-bottom: 20px;
    }
}
.white-text {
    color: #fff;
    margin-bottom: 50px;

    strong {
        font-weight: 400;
    }
}

.blue-text {
    color: #404345;

    &.small-text {
        font-size: 1.6rem;
        margin-bottom: 10px;
    }
    &.mailto a {
        color: inherit;
        text-decoration: underline;

        &:hover {
            margin-bottom: 40px;
            text-decoration: none;
        }
    }
}
.grey-text {
    color: #969696;
    margin-bottom: 40px;
}

.button,
.down-button {
    display: inline-block;
}
.button {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 20px 30px;
    border: none;
    color: #fff;
    font-size: 1.8rem;
    margin-bottom: 70px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;

    &:hover {
        color: #fff;
        text-decoration: none;
        background: rgba(255, 255, 255, 0.3);
    }
}
.down-button {
    background: 0 0;
    border: none;
    margin-bottom: 30px;
    -webkit-animation: jump 0.5s ease-out infinite;
    -moz-animation: jump 0.5s ease-out infinite;
    -o-animation: jump 0.5s ease-out infinite;
    animation: jump 0.5s ease-out infinite;
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;

    &:hover {
        -webkit-animation-play-state: running;
        -moz-animation-play-state: running;
        -o-animation-play-state: running;
        animation-play-state: running;
    }
}
@keyframes jump {
    50% {
        -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -o-transform: translateY(10px);
        transform: translateY(10px);
    }
    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}
@-webkit-keyframes jump {
    50% {
        -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -o-transform: translateY(10px);
        transform: translateY(10px);
    }
    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}
@-moz-keyframes jump {
    50% {
        -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -o-transform: translateY(10px);
        transform: translateY(10px);
    }
    100% {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }
}

.icon-container {
    text-align: center;

    img {
        margin-bottom: 20px;
    }

    p {
        font-weight: 900;
        font-size: 1.4rem;
        line-height: 1.5;
        color: #969696;
    }
    a:hover {
        text-decoration: none;
    }
}
.fix-height,
.logo-grid :first-child {
    margin-bottom: 10px;
}
.wasp {
    padding-right: 0;
}
.fix-height pre {
    border-radius: 0;
    border: none;
    background: #fff;
}
.iframe-text {
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    font-weight: 100;
    font-size: 1.2rem;
    line-height: 1.5;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    color: #afb1b2;
    word-break: break-all;
}
.navbar-toggle {
    border-radius: 100%;
    float: right;
    padding: 9px 5px;
    z-index: 900;
    border: none;
}
.phone-text {
    padding-top: 115px;
}
@media (max-width: 766px) {
    .footer-logo-container {
        text-align: center;
    }
    .footer-navigation {
        text-align: center;
        position: relative;
        z-index: 1;
        margin: 25px 0;
    }
    .footer-navigation ul li {
        display: block !important;
        border: none !important;
    }
    .footer-navigation ul li a {
        line-height: 2.5 !important;
    }
    p {
        line-height: 1.2 !important;
    }
    html {
        font-size: 8px !important;
    }
    #canvas {
        width: 250px !important;
    }
    .preview-screen .computer {
        max-width: 100%;
        margin: 0;
        display: none;
    }
    .share-screen .share-container {
        margin-bottom: 70px;
    }
    .share-screen .image-container img {
        max-width: 50px;
    }
    .title-header {
        font-weight: 100;
        font-size: 4.4rem;
        line-height: 1;
        letter-spacing: -0.1rem;
    }
    .icon-container {
        margin-bottom: 20px;
    }
}
.navbar-nav li.menu-title {
    display: none;
}
@media (max-width: 1000px) {
    .navbar {
        padding: 0;
    }
    .navbar-brand {
        width: 150px;
    }
    .nav-collapse {
        top: 0;
        width: 90%;
        left: 5%;
        margin: 0 !important;
        box-shadow: none;
        position: absolute;
        background: rgba(68, 92, 88, 0.97);
        z-index: 800;
        padding: 0;
        border: 1px solid rgba(255, 255, 255, 0.2) !important;
    }
    .nav-collapse .navbar-nav {
        margin: 0;
    }
    .nav-collapse ul li {
        padding: 0 9%;
    }
    .nav-collapse ul li.menu-title {
        display: block;
        background: #242c2c;
    }
    .nav-collapse ul li.menu-title a {
        text-shadow: 4px 4px 2px rgba(112, 97, 65, 0.6);
        border: none;
    }
    .nav-collapse ul li a {
        font-size: 2.6rem;
        padding: 2rem 0;
        line-height: 1;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    .nav-collapse ul li:last-of-type a {
        border: none;
        padding-bottom: 4rem;
    }
    .nav-collapse ul li:nth-of-type(2) a {
        padding-top: 4rem;
    }
    .navbar-header,
    .navbar-nav,
    .navbar-nav li {
        float: none;
    }
    .navbar-header .navbar-toggle {
        margin-right: 6%;
        margin-top: 2%;
        display: block;
    }
    .navbar-header .navbar-toggle[aria-expanded='true'] .icon-bar {
        display: none;
    }
    .navbar-header .navbar-toggle[aria-expanded='true'] .cross {
        display: block;
    }
    .navbar-header .navbar-toggle:focus,
    .navbar-header .navbar-toggle:hover {
        background: 0 0;
    }
    .navbar-header .navbar-toggle span.icon-bar {
        width: 28px;
        height: 4px;
        background-color: #fff;
    }
    .navbar-header .navbar-toggle .cross {
        display: none;
        color: #fff;
        font-weight: 700;
        font-size: 20px;
        line-height: 1;
    }
}
@media screen and (max-width: 766px) {
    .preview-screen {
        text-align: center;
    }
    .preview-screen .computer {
        margin: 0;
    }
}
[data-time] {
    opacity: 1;
}
.copy-custom a {
    color: #404345;
    text-decoration: underline;
}
.copy-custom a:hover {
    text-decoration: none;
}
.copyright {
    font-size: 1.2rem;
    color: #4d5050;
}
.logo-grid :last-child {
    margin-top: 10px;
}
@media screen and (min-width: 768px) {
    .vertical-center-2 {
        min-height: 207px;
        display: flex;
        align-items: center;
    }
    .vertical-center-1 {
        min-height: 111px;
        display: flex;
        align-items: center;
    }
}
.slick-prev:before,
.slick-next:before {
    font-size: 40px;
}
.slick-prev,
.slick-next {
    top: 50%;
    width: 40px;
    height: 40px;
}
.slick-prev {
    left: -40px;
}
.slick-next {
    right: -40px;
}
.slide {
    padding-bottom: 5px;
}
.slide {
    padding-left: 4px;
    padding-right: 4px;
}
