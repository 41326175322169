$spacer: 5px;

.mt-2{
    margin-top: $spacer * 2;
}

.mb-2{
    margin-bottom: $spacer * 2;
}

.mb-4{
    margin-bottom: $spacer * 4;
}

@media (min-width: 768px) { 
    .text-sm-left{
        text-align: left !important;
    }
 }